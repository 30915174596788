;( function(app, $) {
		var $cache,
			dataGetters,
			providerSchemeConfig,
			fillProviderProductsConfig,
			fillRecommendationBlockHandler,
			addToCartButtonHandler,
			quickViewButtonHandler;

		/**  app.recommendations.customconfig namespace */
		app.recommendations = app.recommendations || {};
		app.recommendations.customconfig = {
			getDataGetters : function(){
				return dataGetters;
			},
			getDataGetters : function(){
				return dataGetters;
			},
			getProviderSchemeConfig : function(){
				return providerSchemeConfig;
			},
			getFillProviderProductsConfig : function(){
				return fillProviderProductsConfig;
			},
			getCache : function(){
				return $cache;
			},
			getFillRecommendationBlockHandler : function(){
				return fillRecommendationBlockHandler;
			},
			getQuickViewButtonHandler : function(){
				return quickViewButtonHandler;
			}
			
		};
}(window.app = window.app || {}, jQuery));
